import { lazy } from "react";

import { RouteConfig } from "./types";

/**  function to lazy-load routes */
const loadModules = (link: string) =>
  lazy(() => import(`../../modules/${link}`));

export const checkoutRoutes: RouteConfig[] = [
  {
    path: "/",
    Component: loadModules("Redirect"),
  },
  {
    path: "qr-pay/:id",
    Component: loadModules("QrPay"),
  },
  {
    path: "checkout/:id",
    Component: loadModules("Checkout"),
  },
  {
    path: "payment/:reference",
    Component: loadModules("TransactionPayment"),
  },
  {
    path: "refund-wallet/:id",
    Component: loadModules("RefundForm"),
  },
  {
    path: "payment-status/:reference",
    Component: loadModules("PaymentStatus"),
  },
];
