import { createSlice } from "@reduxjs/toolkit";

import { ICheckoutPage, ITransactionResponse } from "../components/types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { checkoutApi } from "../services/checkout-service";
export interface checkoutState {
  transactionResponse: ITransactionResponse;
  retryCount: number;
  page: ICheckoutPage;
  isWalletConnect: boolean;
  loading: boolean;
}

const initialState: checkoutState = {
  transactionResponse: {
    address: "",
    baseFiat: "",
    id: null,
    baseFiatToUSDRate: 0,
    crypto: "USDC",
    expectedAmountInUSD: 0,
    feeInUSD: 0,
    expectedAmountInCrypto: 0,
    feeInCrypto: 0,
    expectedAmountWithFeeInCrypto: 0,
    reference: "",
    usdToCryptoRate: 0,
    imageFilePath: "",
    expectedAmountInCryptoPlusFee: 0,
  },
  retryCount: 0,
  page: "checkout",
  isWalletConnect: false,
  loading: false,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    updateTransactionResponse: (
      state,
      {
        payload,
      }: PayloadAction<{
        response: ITransactionResponse;
        page: ICheckoutPage;
      }>
    ) => {
      state.transactionResponse = payload.response;
      state.retryCount++;
      state.page = payload.page;
      state.isWalletConnect = payload.page === "wallet-connect";
    },
    updatePage: (state, { payload }: PayloadAction<ICheckoutPage>) => {
      state.page = payload;
    },
    displayWalletConnect: (state) => {
      state.isWalletConnect = true;
    },
    closeWalletConnet: (state) => {
      state.isWalletConnect = false;
      state.page = "checkout";
    },
    resetCounter: (state) => {
      state.retryCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      checkoutApi.endpoints.rehydration.matchPending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      checkoutApi.endpoints.rehydration.matchFulfilled,
      (state, { payload }: PayloadAction<ITransactionResponse>) => {
        state.transactionResponse = {
          ...payload,
          imageFilePath: state.transactionResponse.imageFilePath,
          expectedAmountWithFeeInCrypto: payload.expectedAmountInCryptoPlusFee,
        };
        state.loading = false;
        state.retryCount++;
      }
    );
    builder.addMatcher(
      checkoutApi.endpoints.rehydration.matchRejected,
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      checkoutApi.endpoints.getTransactionByRef.matchFulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.transactionResponse = payload;
        state.retryCount++;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  updateTransactionResponse,
  updatePage,
  displayWalletConnect,
  closeWalletConnet,
  resetCounter,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
