// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  IBaseFiatResponse,
  IBusinessType,
  IPropsSupportedCurrency,
  ITransactionResponse,
  PaymentLinkResponseType,
} from "../components/types";

// Define a service using a base URL and expected endpoints
export const checkoutApi = createApi({
  reducerPath: "checkoutApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_IVORY_API}/v1`,
  }),
  tagTypes: ["checkout-gateway"],
  endpoints: (builder) => ({
    validatePaymentLink: builder.query<PaymentLinkResponseType, string>({
      query: (reference) => `payment-links/${reference}/pay`,
      transformResponse: (response: { data: PaymentLinkResponseType }) =>
        response.data,
    }),
    verifyBusinessIdentity: builder.query<IBusinessType, string>({
      query: (publicId) => `identities/business/${publicId}`,
      transformResponse: (response: { data: IBusinessType }) => response.data,
    }),
    fetchFiatCurrencies: builder.query<IBaseFiatResponse[], any>({
      query(query) {
        return {
          url: `currencies`,
          method: "GET",
          params: query,
        };
      },
      transformResponse: (response: { data: IBaseFiatResponse[] }) =>
        response.data,
    }),
    fetchBusinessDiscount: builder.query<any, string>({
      query: (id) => `discounts/resource/${id}`,
      transformResponse: (response: { data: any }) => response.data,
    }),
    fetchDiscount: builder.mutation<any, any>({
      query(data) {
        return {
          url: `discounts/applicable-transaction-discounts`,
          method: "POST",
          body: data,
        };
      },
    }),
    makePayment: builder.mutation<any, any>({
      query({ data, id }) {
        return {
          url: `payment-links/${id}/transactions`,
          method: "POST",
          body: data,
        };
      },
    }),
    getTransactionByRef: builder.query<ITransactionResponse, string>({
      query: (reference) => `transactions/${reference}/pay`,
      transformResponse: (response: { data: ITransactionResponse }) =>
        response.data,
    }),
    payWithWalletConnect: builder.mutation<any, any>({
      query({ data, ref }) {
        return {
          url: `transactions/${ref}/wallet-connect`,
          method: "POST",
          body: data,
        };
      },
    }),
    paymentListener: builder.query<any, string>({
      query: (reference) => `transactions/${reference}/verify`,
      transformResponse: (response: { data: any }) => response.data,
    }),
    walletListener: builder.mutation<any, string>({
      query: (reference) => `transactions/${reference}/verify`,
      transformResponse: (response: { data: any }) => response.data,
    }),
    requestRefund: builder.query<any, string>({
      query: (reference) => `refunds/${reference}`,
    }),
    refundWallet: builder.mutation<any, any>({
      query({ data, ref }) {
        return {
          url: `refunds/${ref}/transfer`,
          method: "POST",
          body: data,
        };
      },
    }),
    getCurrency: builder.query<Array<IPropsSupportedCurrency>, string>({
      query: (id) => `businesses/${id}/supported-cryptocurrencies`,
      transformResponse: (response: { data: Array<IPropsSupportedCurrency> }) =>
        response.data,
    }),
    offlinePayment: builder.mutation<any, any>({
      query({ data, publicId }) {
        return {
          url: `qr-pay/initiate`,
          method: "POST",
          body: data,
          params: { publicId },
        };
      },
    }),
    rehydration: builder.mutation({
      query: (id: any) => `/transactions/${id}/rehydration`,
      transformResponse: (response: { data: any }) => response.data,
    }),
  }),
});

export const {
  useValidatePaymentLinkQuery,
  useFetchBusinessDiscountQuery,
  useFetchDiscountMutation,
  useMakePaymentMutation,
  usePayWithWalletConnectMutation,
  usePaymentListenerQuery,
  useRequestRefundQuery,
  useRefundWalletMutation,
  useWalletListenerMutation,
  useGetCurrencyQuery,
  // useFetchFiatCurrenciesQuery,
  useRehydrationMutation,
  useVerifyBusinessIdentityQuery,
  useGetTransactionByRefQuery,
  //useFetchTransactionMutation,
  useOfflinePaymentMutation,
} = checkoutApi;
